import React, {useEffect, useState} from 'react';
import Social from '../components/Social';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Instagram from '../assets/images/icons-insta-white.svg';
import Facebook from '../assets/images/facebook-logo-white.svg';
import linkedin from '../assets/images/icons-linkedin-white.svg';
import SimpleBar from 'simplebar-react';

import {Link, Trans, useI18next, useTranslation} from 'gatsby-plugin-react-i18next';
import {graphql} from "gatsby";
import {Link as LinkGatsby} from "gatsby";
import Image from "gatsby-image";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import SocialInside from "../components/FooterInside";

const EventsPage: React.FC = ({ data }) => {

    const {t} = useTranslation();
    const [pageLoaded, setPageLoaded] = useState(true);
    const [events, setEvents] = useState([]);
    const {language, changeLanguage} = useI18next();

    let pageDetailsFull;

    let allWpPage = data.allWpPage.nodes[0];

    //  Check language
    if (language !== 'en') {

        pageDetailsFull = data.allWpPage?.nodes[0]?.pageDetails;

    }else{

        pageDetailsFull = data.allWpPage?.nodes[0]?.translations[0]?.pageDetails;

    }

    return (
        <Layout>

            <SEO title="Events" />
            <div className="body-wrap">

                <section className="content">

                    <div className="container">

                        <div className="row mainHeaderEvents">

                            {
                                ((isDesktop || isTablet))
                                    ?
                                    <div className="row">

                                        <div className="col-9">
                                            <h1 style={{ paddingLeft: '35%'}}>
                                                {
                                                    (pageDetailsFull) && pageDetailsFull?.title
                                                }
                                            </h1>
                                        </div>
                                        <div className="col-3">
                                            <SocialInside styling={'mobileVersion'}/>
                                        </div>

                                    </div>
                                    :
                                    <div className="row">

                                        <div className="row">

                                            <h1>{pageDetailsFull?.title}</h1>

                                        </div>

                                    </div>
                            }

                        </div>

                        <div className="row">

                            <div className="col">

                                <Image
                                    fluid={allWpPage?.featuredImage?.node?.localFile?.childImageSharp?.fluid}
                                    alt={allWpPage?.featuredImage?.node?.localFile?.altText}
                                    className="img-fluid full"
                                />

                            </div>

                        </div>

                        <div className="row justify-content-center titleDescription titleDescriptionEVT">

                            <div className="col-lg-8 col-sm-12 text-center">
                                <h2>
                                    {
                                        (pageDetailsFull) && pageDetailsFull?.titleH3
                                    }
                                </h2>
                                <p>
                                    {
                                        (pageDetailsFull) && pageDetailsFull?.titleH3Description
                                    }
                                </p>
                            </div>

                        </div>

                    </div>

                </section>

                { data.getPostsCategory.edges.map((item, index) => {

                    let slot9Placed = false;
                    let slot3Placed = false;

                    let countSections = 0;

                    return(

                        <section className="event">

                            <div className="container">

                                <div className="row">

                                    <div className={(index % 2 === 0 ) ? "col section-title" : "col section-title text-end"}>

                                        <h2>{item?.node?.name}</h2>

                                    </div>

                                </div>

                                {(item.node.posts.nodes.length > 1 && index % 2 === 0) &&
                                <div className="row">

                                    {
                                        item.node.posts.nodes.map((post, i) => {

                                            if (post.postAdSlot.largeSection && !slot9Placed) {

                                                slot9Placed = true;

                                                return (
                                                    <div className="col-lg-9 col-sm-12">

                                                        <LinkGatsby
                                                            target={(post.viewingCards?.externalUrl) ? '_blank' : '_self'}
                                                            to={(post.viewingCards?.externalUrl ) ? post?.viewingCards.externalUrl : post?.link}
                                                            className={(post.viewingCards.showBorder === 'Yes' || isMobile ) ? 'hover-link fixed-link slidingGalleryMain textSlider' : 'hover-link  right-link slidingGalleryMain textSlider'}>
                                                            <div className="thumbwrap">
                                                                <Image
                                                                    fluid={post.postAdSlot.mainLargeImagePost?.localFile?.childImageSharp?.fluid}
                                                                    alt={post.postAdSlot.mainLargeImagePost?.alt}
                                                                    className="img-fluid full"
                                                                />
                                                                <span
                                                                    className="more">{post.viewingCards.buttonText}</span>
                                                            </div>
                                                            <h3>{(language !== 'en') ? post?.title : post.translations[0]?.title}</h3>
                                                            <p>{(language !== 'en') ? post?.reading?.smallDescription : post.translations[0]?.smallDescription}</p>
                                                        </LinkGatsby>

                                                    </div>
                                                );
                                            }

                                        })
                                    }

                                    {
                                        item.node.posts.nodes.map((post, i) => {

                                            if (!post.postAdSlot.largeSection && !slot3Placed) {

                                                slot3Placed = true;

                                                return (
                                                    <div className="col-lg-3 col-sm-12">

                                                        <LinkGatsby
                                                            target={(post.viewingCards?.externalUrl) ? '_blank' : '_self'}
                                                            to={(post.viewingCards?.externalUrl) ? post?.viewingCards.externalUrl : post?.link}
                                                            className={(post.viewingCards.showBorder === 'Yes' || isMobile ) ? 'hover-link fixed-link slidingGalleryMain textSlider' : 'hover-link fixed-link slidingGalleryMain textSlider'}>
                                                            <div className="thumbwrap">
                                                                <Image
                                                                    fluid={post.postAdSlot.smallImagePost?.localFile?.childImageSharp?.fluid}
                                                                    alt={post.postAdSlot.smallImagePost?.alt}
                                                                    className="img-fluid full"
                                                                />
                                                                <span
                                                                    className="more events">{post.viewingCards.buttonText}</span>
                                                            </div>
                                                            <h3>{(language !== 'en') ? post?.title : post.translations[0]?.title}</h3>
                                                            <p>{(language !== 'en') ? post?.reading?.smallDescription : post.translations[0]?.smallDescription}</p>
                                                        </LinkGatsby>

                                                    </div>
                                                );
                                            }

                                        })
                                    }

                                </div>
                                }

                                {(item.node.posts.nodes.length > 1 && index % 2 !== 0) &&
                                <div className="row">

                                    {
                                        item.node.posts.nodes.map((post, i) => {

                                            if (!post.postAdSlot.largeSection && !slot3Placed) {

                                                slot3Placed = true;

                                                return (
                                                    <div className="col-lg-3 col-sm-12">

                                                        <LinkGatsby
                                                            target={(post.viewingCards?.externalUrl) ? '_blank' : '_self'}
                                                            to={(post.viewingCards?.externalUrl) ? post?.viewingCards.externalUrl : post?.link}
                                                            className={(post.viewingCards.showBorder === 'Yes' || isMobile) ? 'hover-link fixed-link slidingGalleryMain textSlider' : 'hover-link fixed-link slidingGalleryMain textSlider'}>
                                                            <div className="thumbwrap">
                                                                <Image
                                                                    fluid={post.postAdSlot.smallImagePost?.localFile?.childImageSharp?.fluid}
                                                                    alt={post.postAdSlot.smallImagePost?.alt}
                                                                    className="img-fluid full"
                                                                />
                                                                <span
                                                                    className="more events">{post.viewingCards.buttonText}</span>
                                                            </div>
                                                            <h3>{(language !== 'en') ? post?.title : post.translations[0]?.title}</h3>
                                                            <p>{(language !== 'en') ? post?.reading?.smallDescription : post.translations[0]?.smallDescription}</p>
                                                        </LinkGatsby>

                                                    </div>
                                                );
                                            }

                                        })
                                    }

                                    {
                                        item.node.posts.nodes.map((post, i) => {

                                            if (post.postAdSlot.largeSection && !slot9Placed) {

                                                slot9Placed = true;

                                                return (
                                                    <div className="col-lg-9 col-sm-12">

                                                        <LinkGatsby
                                                            target={(post.viewingCards?.externalUrl) ? '_blank' : '_self'}
                                                            to={(post.viewingCards?.externalUrl) ? post?.viewingCards.externalUrl : post?.link}
                                                            className={(post.viewingCards.showBorder === 'Yes' || isMobile ) ? 'hover-link fixed-link slidingGalleryMain textSlider' : 'hover-link  right-link slidingGalleryMain textSlider'}>
                                                            <div className="thumbwrap">
                                                                <Image
                                                                    fluid={post.postAdSlot.mainLargeImagePost?.localFile?.childImageSharp?.fluid}
                                                                    alt={post.postAdSlot.mainLargeImagePost?.alt}
                                                                    className="img-fluid full"
                                                                />
                                                                <span
                                                                    className="more">{post.viewingCards.buttonText}</span>
                                                            </div>
                                                            <h3>{(language !== 'en') ? post?.title : post.translations[0]?.title}</h3>
                                                            <p>{(language !== 'en') ? post?.reading?.smallDescription : post.translations[0]?.smallDescription}</p>
                                                        </LinkGatsby>

                                                    </div>
                                                );
                                            }

                                        })
                                    }

                                </div>
                                }

                            </div>

                        </section>
                    );
                })}

            </div>

        </Layout>

    );
};

export default EventsPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    },
    allWpPage(filter: {databaseId: {eq: 469}}) {
        nodes {
          databaseId
          title
          pageDetails {
            title
            titleH3
            titleH3Description
            titleSection
            archiveShowMorePosts
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1179, maxHeight:560, fit: COVER, quality: 65, srcSetBreakpoints: [ 400, 600, 800, 1179 ]) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
          translations {
            pageDetails {
              title
              titleH3
              titleSection
              titleH3Description
              archiveShowMorePosts
            }
          }
        }
      },
      getPostsCategory: allWpCategory(
        filter: {parentDatabaseId: {eq: 68}}
        sort: {fields: name, order: ASC}
      ) {
        edges {
          node {
            name
            posts {
              nodes {
                title
                link,
                reading {
                  minuteRead
                  secondtitle
                  smallDescription
                }
                viewingCards {
                  buttonText
                  externalUrl
                  showBorder
                }
                postAdSlot {
                  mainLargeImagePost {
                    altText
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 880, maxHeight: 420, quality: 80, srcSetBreakpoints: [ 400, 600, 880 ]) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }
                  smallImagePost {
                    altText
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 280,maxHeight: 420, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }
                  largeSection
                }
                translations {
                  title
                  reading {
                    minuteRead
                    secondtitle
                    smallDescription
                  }
                }
                viewingCards {
                  buttonText
                  externalUrl
                  showBorder
                }
              }
            }
          }
        }
        totalCount
      }
  }
`;